import { Outlet } from "react-router-dom";

/**
 * Route: /m/:meetingId
 */
export default function MeetingRoute() {
  return (
    <>
      {/* TODO: Add navigation */}
      <Outlet />
    </>
  );
}
